import React, { useEffect, useState, Component } from "react";
import statCollectionClass from "./tennis-stats";
import Table from "react-bootstrap/Table";
import { Check, CircleFill } from "react-bootstrap-icons";

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function doesEventHappen(odds) {
  return randomIntFromInterval(1, 100) <= odds;
}

function pointStr(pts) {
  return pts >= 0 ? pts : "AD";
}

class tennisMatch {
  playerServing;
  activeSet;
  pointsWonInGame = [0, 0];
  gamesWonInSet = [];
  tiebreakPointsWonInSet = [];
  setsWonInMatch = [0, 0];
  isFinished = false;
  isTiebreakToPoints = 0;
  wasServerBeforeTiebreak = null;
  gamesNeededForThisSet;
  playerWon = null;
  lastPointWinner = null;

  id;
  stats;
  rules;
  odds;
  debug;

  constructor(counter, rules, odds, debug) {
    this.id = "id" + Math.random().toString(16) + counter;
    this.rules = rules;
    this.odds = odds;
    this.debug = debug;
    this.gamesNeededForThisSet = this.rules.gamesNeededForSet;

    this.playerServing = doesEventHappen(50) ? 1 : 0;
    this.activeSet = 1;
    this.gamesWonInSet[this.activeSet] = [0, 0];
    this.tiebreakPointsWonInSet[this.activeSet] = [0, 0];

    this.stats = new statCollectionClass(); // init stats
  }

  playNextPoint() {
    let pointWinner = null;
    let pointLoser = null;

    if (this.debug) console.log(this.resultToString()); // otherwise printed at end of loop

    // is first serve in?
    // stat+ points played by player
    if (doesEventHappen(this.odds.firstServePercentage[this.playerServing])) {
      // stat+ firstServes in by player
      this.stats.collection.firstServeIn.successes[this.playerServing]++;
      this.stats.collection.firstServeWon.totalAmount[this.playerServing]++;
      this.stats.collection.servicePointsWon.totalAmount[this.playerServing]++;
      this.stats.collection.returnPointsWon.totalAmount[
        this.playerServing ^ 1
      ]++;
      if (doesEventHappen(this.odds.winningOnFirstServe[this.playerServing])) {
        // stat+ firstServesWon by player
        pointWinner = this.playerServing;
        this.stats.collection.firstServeWon.successes[pointWinner]++;
        this.stats.collection.servicePointsWon.successes[pointWinner]++;
      } else {
        pointWinner = this.playerServing ^ 1; // switcharoo
        this.stats.collection.returnPointsWon.successes[pointWinner]++;
      }
    } else {
      // is second serve in?
      this.stats.collection.secondServeIn.totalAmount[this.playerServing]++;
      this.stats.collection.servicePointsWon.totalAmount[this.playerServing]++;
      this.stats.collection.returnPointsWon.totalAmount[
        this.playerServing ^ 1
      ]++;
      if (
        doesEventHappen(this.odds.secondServePercentage[this.playerServing])
      ) {
        this.stats.collection.secondServeIn.successes[this.playerServing]++;
        this.stats.collection.secondServeWon.totalAmount[this.playerServing]++;
        if (
          doesEventHappen(this.odds.winningOnSecondServe[this.playerServing])
        ) {
          pointWinner = this.playerServing;
          this.stats.collection.secondServeWon.successes[pointWinner]++;
          this.stats.collection.servicePointsWon.successes[pointWinner]++;
        } else {
          pointWinner = this.playerServing ^ 1;
          this.stats.collection.returnPointsWon.successes[pointWinner]++;
        }
      } else {
        // stat+ doubleFaults
        pointWinner = this.playerServing ^ 1;
        this.stats.collection.doubleFaults.successes[this.playerServing]++;
        this.stats.collection.doubleFaults.totalAmount[this.playerServing]++;
        this.stats.collection.returnPointsWon.successes[pointWinner]++;
      }
    }
    this.stats.collection.firstServeIn.totalAmount[this.playerServing]++;
    // stat+ pointsWon by player
    pointLoser = pointWinner ^ 1;
    this.lastPointWinner = pointWinner;

    let gameWasWon = false;
    if (this.isTiebreakToPoints === 0) {
      switch (this.pointsWonInGame[pointWinner]) {
        case 0:
          this.pointsWonInGame[pointWinner] = 15;
          break;
        case 15:
          this.pointsWonInGame[pointWinner] = 30;
          break;
        case 30:
          this.pointsWonInGame[pointWinner] = 40;
          break;
        case 40:
          if (this.rules.noAD) {
            gameWasWon = true;
          } else if (this.pointsWonInGame[pointLoser] < 40) {
            // does loser have AD?
            if (this.pointsWonInGame[pointLoser] < 0) {
              this.pointsWonInGame[pointWinner] = 40; // back to deuce
              this.pointsWonInGame[pointLoser] = 40;
            } else {
              // game won by pointWinner
              gameWasWon = true;
            }
          } else {
            this.pointsWonInGame[pointWinner] = -1; // AD
            this.pointsWonInGame[pointLoser] = 40;
            if (pointWinner !== this.playerServing) {
              this.stats.collection.breakPointsWon.totalAmount[pointWinner]++;
              // console.log('Is breakpoint next for player '+((this.playerServing ^ 1)+1)+' (receiver has AD)');
            }
          }
          break;
        case -1: // AD
          gameWasWon = true;
          break;
      }
      // count breakpoints for non-AD situations; can occur when either wins point
      if (!gameWasWon) {
        if (this.rules.noAD) {
          if (this.pointsWonInGame[this.playerServing ^ 1] === 40) {
            this.stats.collection.breakPointsWon.totalAmount[
              this.playerServing ^ 1
            ]++;
            // console.log('Is breakpoint next for player '+((this.playerServing ^ 1)+1)+' (receiver has 40)');
          }
        } else {
          if (
            this.pointsWonInGame[this.playerServing ^ 1] === 40 &&
            this.pointsWonInGame[this.playerServing] < 40 &&
            this.pointsWonInGame[this.playerServing] > 0
          ) {
            this.stats.collection.breakPointsWon.totalAmount[
              this.playerServing ^ 1
            ]++;
            // console.log('Is breakpoint next for player '+((this.playerServing ^ 1)+1)+' (receiver has 40, server has less)');
          }
        }
      }
    } else {
      // tiebreak
      this.pointsWonInGame[pointWinner]++;
      if (
        (this.pointsWonInGame[pointWinner] + this.pointsWonInGame[pointLoser]) %
          2 ===
        1
      ) {
        // console.log('Switch server in tiebreak after points played are '+(this.pointsWonInGame[pointWinner]+this.pointsWonInGame[pointLoser]));
        this.playerServing ^= 1; // switch server every two points in tiebreak
      }
      if (
        this.pointsWonInGame[pointWinner] >= this.isTiebreakToPoints &&
        this.pointsWonInGame[pointWinner] - this.pointsWonInGame[pointLoser] >=
          2
      ) {
        gameWasWon = true;
        this.tiebreakPointsWonInSet[this.activeSet][pointWinner] =
          this.pointsWonInGame[pointWinner];
        this.tiebreakPointsWonInSet[this.activeSet][pointLoser] =
          this.pointsWonInGame[pointLoser];
      }
    }
    let setWasWon = false;
    if (gameWasWon) {
      // register if was breakpoint (never for tiebreaks)
      if (this.isTiebreakToPoints === 0 && pointWinner !== this.playerServing) {
        this.stats.collection.breakPointsWon.successes[pointWinner]++;
        // console.log('Breakpoint won for player '+((this.playerServing ^ 1)+1));
      }

      this.playerServing ^= 1; // switch server
      this.gamesWonInSet[this.activeSet][pointWinner]++;
      // stat+ gamesWon by player
      this.pointsWonInGame[pointWinner] = 0;
      this.pointsWonInGame[pointLoser] = 0;

      if (
        this.gamesWonInSet[this.activeSet][pointWinner] >=
        this.gamesNeededForThisSet
      ) {
        // check if set is won by leading by two or more
        let separationNeeded = 2;
        if (this.isTiebreakToPoints > 0) separationNeeded = 1;
        if (
          this.gamesWonInSet[this.activeSet][pointWinner] -
            this.gamesWonInSet[this.activeSet][pointLoser] >=
          separationNeeded
        ) {
          setWasWon = true;
        } else {
          // both have equal amount of games
          if (
            this.gamesWonInSet[this.activeSet][pointWinner] ===
            this.gamesWonInSet[this.activeSet][pointLoser]
          ) {
            // if is last set, different rules
            if (
              this.setsWonInMatch[pointWinner] ===
                this.rules.setsNeededForWin - 1 &&
              this.setsWonInMatch[pointLoser] ===
                this.rules.setsNeededForWin - 1
            ) {
              if (
                this.rules.lastSetMatchTiebreakStartingAtGames ===
                this.gamesWonInSet[this.activeSet][pointWinner]
              ) {
                // next up final set tiebreak
                this.isTiebreakToPoints = this.rules.lastSetMatchTiebreak;
              }
            } else {
              // next up tiebreak
              this.isTiebreakToPoints = this.rules.normalTiebreakToPoints;
              // save server
              this.wasServerBeforeTiebreak = this.playerServing;
            }
          }
        }
      }

      if (setWasWon) {
        this.isTiebreakToPoints = 0;
        this.gamesNeededForThisSet = this.rules.gamesNeededForSet; // default to this
        // stat+ setsWon by player
        this.setsWonInMatch[pointWinner]++;
        if (this.setsWonInMatch[pointWinner] >= this.rules.setsNeededForWin) {
          this.isFinished = true;
          this.playerWon = pointWinner;
        } else {
          this.activeSet++;
          this.gamesWonInSet[this.activeSet] = [0, 0];
          this.tiebreakPointsWonInSet[this.activeSet] = [0, 0];

          // start last set tiebreak if it starts at zero
          if (
            this.setsWonInMatch[pointWinner] ===
              this.rules.setsNeededForWin - 1 &&
            this.setsWonInMatch[pointLoser] ===
              this.rules.setsNeededForWin - 1 &&
            this.rules.lastSetMatchTiebreak > 0 &&
            this.rules.lastSetMatchTiebreakStartingAtGames === 0
          ) {
            this.isTiebreakToPoints = this.rules.lastSetMatchTiebreak;
            this.gamesNeededForThisSet = 1;
          }
        }
        this.stats.collection.setsWon.successes[pointWinner]++;
        this.stats.collection.setsWon.totalAmount[pointWinner]++;
        this.stats.collection.setsWon.totalAmount[pointLoser]++;

        // set server after tiebreak
        if (this.wasServerBeforeTiebreak !== null) {
          this.playerServing = this.wasServerBeforeTiebreak ^ 1;
          this.wasServerBeforeTiebreak = null;
        }
      }
      this.stats.collection.gamesWon.successes[pointWinner]++;
      this.stats.collection.gamesWon.totalAmount[pointWinner]++;
      this.stats.collection.gamesWon.totalAmount[pointLoser]++;
    }
    this.stats.collection.pointsWon.successes[pointWinner]++;
    this.stats.collection.pointsWon.totalAmount[pointWinner]++;
    this.stats.collection.pointsWon.totalAmount[pointLoser]++;

    if (this.debug) console.log(this.resultToString());
    // point played

    if (this.isFinished) {
      this.stats.collection.matchesWon.successes[pointWinner]++;
      this.stats.collection.matchesWon.totalAmount[pointWinner]++;
      this.stats.collection.matchesWon.totalAmount[pointLoser]++;
      this.stats.collection.matchesWonOdds.successes[pointWinner]++;
      this.stats.collection.matchesWonOdds.totalAmount[pointWinner]++;
      this.stats.collection.matchesWonOdds.totalAmount[pointLoser]++;
      if (this.debug) console.log(this.stats.statsToString(1));
    }
    return;
  }

  resultToString() {
    let s = "";
    s += "Sets: " + this.setsWonInMatch[0] + "-" + this.setsWonInMatch[1];
    s += " [";
    let numOfSets = this.setsWonInMatch[0] + this.setsWonInMatch[1];
    if (!this.isFinished) numOfSets++;
    for (let setIndex = 1; setIndex <= numOfSets; setIndex++) {
      s +=
        " " +
        this.gamesWonInSet[setIndex][0] +
        "-" +
        this.gamesWonInSet[setIndex][1];
      if (
        this.tiebreakPointsWonInSet[setIndex][0] +
          this.tiebreakPointsWonInSet[setIndex][1] >
        0
      ) {
        s +=
          "(" +
          Math.min(
            this.tiebreakPointsWonInSet[setIndex][0],
            this.tiebreakPointsWonInSet[setIndex][1]
          ) +
          ")";
      }
      s += " ";
    }
    s += "]";
    if (this.isFinished) {
      s += ", winner: Player " + (this.playerWon + 1);
    } else {
      s +=
        ", " +
        pointStr(this.pointsWonInGame[0]) +
        "-" +
        pointStr(this.pointsWonInGame[1]);
      s += ", player " + (this.playerServing + 1) + " serving ";
    }
    return s;
  }

  writePoint(playerIndex) {
    let retStr = this.pointsWonInGame[playerIndex] ?? "";
    if ((this.pointsWonInGame[playerIndex] ?? 0) < 0) retStr = "AD";
    return retStr;
  }

  resultToTable() {
    let maxSets = this.rules.setsNeededForWin * 2 - 1;
    return (
      <Table
        responsive
        bordered
        hover
        className={
          "single-match-result" +
          (this.isFinished ? " is-finished" : " in-progress")
        }
      >
        <tbody>
          {[...Array(2)].map((x, playerIndex) => (
            <tr
              key={playerIndex}
              className={
                this.lastPointWinner === playerIndex
                  ? "last-point-winner"
                  : undefined
              }
            >
              <td
                width="50%"
                key="player"
                className={
                  this.playerWon === playerIndex ? "fw-bold" : undefined
                }
              >
                Player {playerIndex + 1}
              </td>
              <td width="10%" key="status" className="status-cell text-center">
                {this.isFinished && this.playerWon === playerIndex && (
                  <Check color="green" />
                )}
                {!this.isFinished && this.playerServing === playerIndex && (
                  <CircleFill className="tennis-ball-color" />
                )}
              </td>
              <td
                key="sets"
                className="sets-won-in-match text-center table-primary"
              >
                {this.setsWonInMatch[playerIndex]}
              </td>
              {[...Array(maxSets)].map((x, setIndex) => (
                <td className="games-won-in-set text-center" key={setIndex}>
                  {this.gamesWonInSet[setIndex + 1] &&
                    (this.gamesWonInSet[setIndex + 1][playerIndex] ?? "")}
                  {this.activeSet > setIndex &&
                    (this.tiebreakPointsWonInSet[setIndex + 1][0] ?? 0) +
                      (this.tiebreakPointsWonInSet[setIndex + 1][1] ?? 0) >
                      0 && (
                      <sup>
                        {this.tiebreakPointsWonInSet[setIndex + 1][playerIndex]}
                      </sup>
                    )}
                </td>
              ))}
              <td
                width="10%"
                key="pts"
                className="points-won-in-game text-center table-active"
              >
                {this.writePoint(playerIndex)}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }
}

export default tennisMatch;
