import React, { useState, useRef } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import ReactSlider from "react-slider";
import { RulePresetInterface } from "./types";

interface ColElementInterface {
  w: number;
  id?: string;
  className?: string;
  inputLabel?: string;
  marks?: Record<string, string>;
  onSliderChange?: (value: any, id: string) => void;
  onChange?: React.ChangeEventHandler;
  onClick?: React.MouseEventHandler;
  isRunning?: boolean;
  isDisabled?: boolean;
  includeCustom?: boolean;
  value?: number;
}

function ColLabel({ w, inputLabel, className }: ColElementInterface) {
  return (
    <Col xs={w} className={className}>
      <Form.Label>{inputLabel}</Form.Label>
    </Col>
  );
}

function ColSliderPercentageInput({
  w,
  id,
  onSliderChange,
  isRunning,
  value,
}: ColElementInterface) {
  const [isInputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  const handleSliderDoubleClick = () => {
    setInputVisible(true);
  };

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    if (newValue === "" || isNaN(newValue)) {
      setInputValue(newValue);
      return;
    }
    setInputValue(parseInt(newValue));
    onSliderChange(parseInt(newValue), id);
  };

  const handleInputBlur = (event) => {
    let newValue = event.target.value;
    if (newValue === "" || isNaN(newValue)) newValue = 0;
    setInputValue(parseInt(newValue));
    setInputVisible(false);
  };

  return (
    <Col xs={w}>
      {isInputVisible ? (
        <InputGroup>
          <Form.Control
            type="number"
            value={inputValue}
            className="text-end"
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            autoFocus
          />
        </InputGroup>
      ) : (
        <ReactSlider
          id={id}
          name={id}
          className="horizontal-slider"
          thumbClassName="tennis-ball-thumb"
          trackClassName="slider-track"
          onAfterChange={(value) => {
            onSliderChange(value, id);
            setInputValue(value);
          }}
          defaultValue={inputValue}
          disabled={isRunning ? true : false}
          renderThumb={(props, state) => (
            <div {...props} onDoubleClick={handleSliderDoubleClick}>
              {state.valueNow}&nbsp;%
            </div>
          )}
        />
      )}
    </Col>
  );
}

function ColSliderMarkInput({
  w,
  id,
  marks,
  onSliderChange,
  isRunning,
  value,
}: ColElementInterface) {
  var markIndexNumbers = [];
  Object.entries(marks).forEach(([key]) => {
    markIndexNumbers.push(parseInt(key));
  });
  return (
    <Col xs={w}>
      <ReactSlider
        id={id}
        name={id}
        marks={markIndexNumbers}
        min={Math.min(...markIndexNumbers)}
        max={Math.max(...markIndexNumbers)}
        className="horizontal-slider mark-slider"
        thumbClassName="tennis-ball-thumb"
        trackClassName="slider-track"
        markClassName="slider-mark"
        onAfterChange={(value) => onSliderChange(value, id)}
        defaultValue={value}
        disabled={isRunning ? true : false}
        renderThumb={(props, state) => (
          <div {...props}>{marks[state.valueNow]}</div>
        )}
      />
    </Col>
  );
}

function ColNumberInput({
  w,
  id,
  onChange,
  isRunning,
  value,
}: ColElementInterface) {
  return (
    <Col xs={w}>
      <InputGroup>
        <Form.Control
          id={id}
          name={id}
          className="text-end"
          type="number"
          onChange={onChange}
          disabled={isRunning ? true : false}
          value={value}
        />
      </InputGroup>
    </Col>
  );
}

function ColSelectInput({
  w,
  id,
  onChange,
  isRunning,
  value,
  marks,
  includeCustom,
}: ColElementInterface) {
  const selectElementOptions = [];
  Object.entries(marks).forEach(([key]) => {
    const inputLabel = marks[key] ?? "";
    selectElementOptions.push(
      <option key={key} value={key}>
        {inputLabel}
      </option>
    );
  });
  return (
    <Col xs={w}>
      <InputGroup>
        <Form.Select
          id={id}
          name={id}
          onChange={onChange}
          disabled={isRunning ? true : false}
          value={value}
        >
          {selectElementOptions}
          {includeCustom && (
            <option key="custom" value="custom">
              Custom
            </option>
          )}
        </Form.Select>
      </InputGroup>
    </Col>
  );
}

function ColBooleanInput({
  w,
  id,
  onChange,
  isRunning,
  value,
}: ColElementInterface) {
  return (
    <Col xs={w} className="py-2">
      <InputGroup>
        <Form.Check
          inline
          key={id + "_1"}
          label="Yes"
          id={id + "_1"}
          name={id}
          type="radio"
          onChange={onChange}
          disabled={isRunning ? true : false}
          checked={value ? true : false}
          value={1}
        />
        <Form.Check
          inline
          key={id + "_0"}
          label="No"
          id={id + "_0"}
          name={id}
          type="radio"
          onChange={onChange}
          disabled={isRunning ? true : false}
          checked={value ? false : true}
          value={0}
        />
      </InputGroup>
    </Col>
  );
}

function ColStartButton({
  w,
  onClick,
  inputLabel,
  isDisabled,
}: ColElementInterface) {
  return (
    <Col xs={w}>
      <Button
        variant="light"
        className="startButton"
        onClick={onClick}
        disabled={isDisabled}
      >
        {inputLabel}
      </Button>
    </Col>
  );
}

export function MatchInputs({
  onSubmit,
  onSliderChange,
  submitLabel,
  matchLabels,
  matchDefaults,
  inputs,
  isRunning,
}) {
  let sp1_1 =
    inputs.sp1_1 !== undefined
      ? inputs.sp1_1
      : matchDefaults.firstServePercentage[0];
  let sp1_2 =
    inputs.sp1_2 !== undefined
      ? inputs.sp1_2
      : matchDefaults.firstServePercentage[1];
  let sw1_1 =
    inputs.sw1_1 !== undefined
      ? inputs.sw1_1
      : matchDefaults.winningOnFirstServe[0];
  let sw1_2 =
    inputs.sw1_2 !== undefined
      ? inputs.sw1_2
      : matchDefaults.winningOnFirstServe[1];
  let sp2_1 =
    inputs.sp2_1 !== undefined
      ? inputs.sp2_1
      : matchDefaults.secondServePercentage[0];
  let sp2_2 =
    inputs.sp2_2 !== undefined
      ? inputs.sp2_2
      : matchDefaults.secondServePercentage[1];
  let sw2_1 =
    inputs.sw2_1 !== undefined
      ? inputs.sw2_1
      : matchDefaults.winningOnSecondServe[0];
  let sw2_2 =
    inputs.sw2_2 !== undefined
      ? inputs.sw2_2
      : matchDefaults.winningOnSecondServe[1];

  let badInputValues = false; // some input combinations cause neverending matches
  let badInputMsg = "";
  // neither player wins any points
  if (sw1_1 == 0 && sw1_2 == 0 && sw2_1 == 0 && sw2_2 == 0)
    badInputValues = true;
  // neither player makes any first serves or wins any points on second serve
  if (sp1_1 == 0 && sp1_2 == 0 && sw2_1 == 0 && sw2_2 == 0)
    badInputValues = true;
  // both players make all first serves but don't win any points
  if (sp1_1 == 100 && sp1_2 == 100 && sw1_1 == 0 && sw1_2 == 0)
    badInputValues = true;
  // both players win all points
  if (sw1_1 == 100 && sw1_2 == 100 && sw2_1 == 100 && sw2_2 == 100)
    badInputValues = true;
  // neither player makes any first serves, but win all points on second serve (have to make all serves)
  if (
    sp1_1 == 0 &&
    sp1_2 == 0 &&
    sp2_1 == 100 &&
    sw2_1 == 100 &&
    sp2_2 == 100 &&
    sw2_2 == 100
  )
    badInputValues = true;
  // both players make all first serves and win all points
  if (sp1_1 == 100 && sp1_2 == 100 && sw1_1 == 100 && sw1_2 == 100)
    badInputValues = true;
  if (badInputValues) badInputMsg = "Values lead to never-ending matches!";

  // Also check for dumb rules
  if (inputs.snfw < 1) {
    badInputValues = true;
    badInputMsg = "Sets needed for win can't be less than one.";
  }

  if (inputs.gnfs < 1) {
    badInputValues = true;
    badInputMsg = "Games needed for set can't be less than one.";
  }

  if (inputs.ntbtp < 1) {
    badInputValues = true;
    badInputMsg = "Normal tiebreak to points can't be less than one.";
  }

  if (inputs.lsmtb < 1) {
    badInputValues = true;
    badInputMsg = "Last set tiebreak to points can't be less than one.";
  }

  return (
    <>
      <Row className="table-header">
        <ColLabel w={4} inputLabel="" className="" />
        <Col xs={4} className="text-center">
          Player 1
        </Col>
        <Col xs={4} className="text-center">
          Player 2
        </Col>
      </Row>
      <Row>
        <ColLabel
          w={4}
          inputLabel={matchLabels.firstServePercentage}
          className=""
        />
        <ColSliderPercentageInput
          w={4}
          id="sp1_1"
          onSliderChange={onSliderChange}
          isRunning={isRunning}
          value={sp1_1}
        />
        <ColSliderPercentageInput
          w={4}
          id="sp1_2"
          onSliderChange={onSliderChange}
          isRunning={isRunning}
          value={sp1_2}
        />
      </Row>
      <Row>
        <ColLabel
          w={4}
          inputLabel={matchLabels.winningOnFirstServe}
          className=""
        />
        <ColSliderPercentageInput
          w={4}
          id="sw1_1"
          onSliderChange={onSliderChange}
          isRunning={isRunning}
          value={sw1_1}
        />
        <ColSliderPercentageInput
          w={4}
          id="sw1_2"
          onSliderChange={onSliderChange}
          isRunning={isRunning}
          value={sw1_2}
        />
      </Row>
      <Row>
        <ColLabel
          w={4}
          inputLabel={matchLabels.secondServePercentage}
          className=""
        />
        <ColSliderPercentageInput
          w={4}
          id="sp2_1"
          onSliderChange={onSliderChange}
          isRunning={isRunning}
          value={sp2_1}
        />
        <ColSliderPercentageInput
          w={4}
          id="sp2_2"
          onSliderChange={onSliderChange}
          isRunning={isRunning}
          value={sp2_2}
        />
      </Row>
      <Row>
        <ColLabel
          w={4}
          inputLabel={matchLabels.winningOnSecondServe}
          className=""
        />
        <ColSliderPercentageInput
          w={4}
          id="sw2_1"
          onSliderChange={onSliderChange}
          isRunning={isRunning}
          value={sw2_1}
        />
        <ColSliderPercentageInput
          w={4}
          id="sw2_2"
          onSliderChange={onSliderChange}
          isRunning={isRunning}
          value={sw2_2}
        />
      </Row>
      <hr />
      <Row>
        <ColLabel w={4} inputLabel={matchLabels.runDetailLabel} className="" />
        <ColSliderMarkInput
          w={4}
          id="rc"
          marks={matchDefaults.runDetail}
          onSliderChange={onSliderChange}
          isRunning={isRunning}
          value={inputs.rc}
        />
        <Col xs={4} className="slider-selection-info">
          {matchDefaults.runDetailDetails[inputs.rc]}
        </Col>
      </Row>
      <Row>
        <ColStartButton
          w={6}
          onClick={onSubmit}
          inputLabel={submitLabel}
          isDisabled={badInputValues}
        />
        {badInputValues && (
          <Col xs={6}>
            <Alert variant="danger">{badInputMsg}</Alert>
          </Col>
        )}
      </Row>
    </>
  );
}

export function RuleInputs({
  onChange,
  onPresetChange,
  ruleLabels,
  rulePresets,
  inputs,
  isRunning,
}: {
  onChange: React.ChangeEventHandler;
  onPresetChange: React.ChangeEventHandler;
  ruleLabels: Record<string, string>;
  rulePresets: Record<string, RulePresetInterface>;
  inputs: Record<string, any>;
  isRunning: boolean;
}) {
  const presetList: Record<string, string> = {};
  Object.entries(rulePresets).forEach(([key, obj]) => {
    presetList[key] = obj.ruleLabel;
  });
  return (
    <>
      <Row>
        <ColLabel w={6} inputLabel={ruleLabels.rulePreset} className="" />
        <ColSelectInput
          w={6}
          id="preset"
          onChange={onPresetChange}
          isRunning={isRunning}
          value={inputs.preset}
          marks={presetList}
          includeCustom={true}
        />
      </Row>
      <Row>
        <ColLabel w={6} inputLabel={ruleLabels.setsNeededForWin} className="" />
        <ColNumberInput
          w={6}
          id="snfw"
          onChange={onChange}
          isRunning={isRunning}
          value={inputs.snfw}
        />
      </Row>
      <Row>
        <ColLabel
          w={6}
          inputLabel={ruleLabels.gamesNeededForSet}
          className=""
        />
        <ColNumberInput
          w={6}
          id="gnfs"
          onChange={onChange}
          isRunning={isRunning}
          value={inputs.gnfs}
        />
      </Row>
      <Row>
        <ColLabel w={6} inputLabel={ruleLabels.noAD} className="py-2" />
        <ColBooleanInput
          w={6}
          id="noad"
          onChange={onChange}
          isRunning={isRunning}
          value={inputs.noad}
        />
      </Row>
      <Row>
        <ColLabel
          w={6}
          inputLabel={ruleLabels.normalTiebreakToPoints}
          className=""
        />
        <ColNumberInput
          w={6}
          id="ntbtp"
          onChange={onChange}
          isRunning={isRunning}
          value={inputs.ntbtp}
        />
      </Row>
      <Row>
        <ColLabel
          w={6}
          inputLabel={ruleLabels.lastSetMatchTiebreak}
          className=""
        />
        <ColNumberInput
          w={6}
          id="lsmtb"
          onChange={onChange}
          isRunning={isRunning}
          value={inputs.lsmtb}
        />
      </Row>
      <Row>
        <ColLabel
          w={6}
          inputLabel={ruleLabels.lastSetMatchTiebreakStartingAtGames}
          className=""
        />
        <ColNumberInput
          w={6}
          id="lsmtbsag"
          onChange={onChange}
          isRunning={isRunning}
          value={inputs.lsmtbsag}
        />
      </Row>
    </>
  );
}
