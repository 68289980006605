import React, { useState, StrictMode } from "react";
import { createRoot } from "react-dom/client";

import "./styles/custom.scss";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MatchSimulatorApp from "./match-app";
import { inject } from "@vercel/analytics";

inject();

const App = () => (
  <Container fluid>
    <Row>
      <Col className="mb-2 tennis-secondary tennis-header">
        <h1>Tennis Match Simulator</h1>
        <h4 className="tagline">
          <i>"What if they played 100 matches?"</i>
        </h4>
      </Col>
    </Row>
    <MatchSimulatorApp />
    <Row>
      <Col className="mt-2 tennis-secondary tennis-footer">
        <Row>
          <Col xs={6}>
            <p>&copy; Nomasi Oy</p>
          </Col>
          <Col xs={6}>
            <p className="text-end">
              All feedback is welcome at&nbsp;
              <a href="mailto:tms@nomasi.com">tms@nomasi.com</a>
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  </Container>
);

createRoot(document.getElementById("root")).render(<App />);
