import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class singleStatClass extends Component {
  key = "";
  name = "";
  totalAmount = [0, 0];
  successes = [0, 0];

  constructor(key, name) {
    super(key, name);
    this.key = key;
    this.name = name;
  }

  playerStatToString(player, divider) {
    let s = "";
    switch (this.key) {
      case "doubleFaults":
        s += Number(this.successes[player] / divider).toFixed(1);
        break;
      case "matchesWon":
        s +=
          (this.totalAmount[player] > 0
            ? Number(
                (this.successes[player] / this.totalAmount[player]) * 100
              ).toFixed(1)
            : "-") + " %";
        break;
      case "matchesWonOdds":
        s +=
          this.totalAmount[player] > 0 && this.successes[player] > 0
            ? Number(this.totalAmount[player] / this.successes[player]).toFixed(
                2
              )
            : "-";
        break;
      default:
        s +=
          (this.totalAmount[player] > 0
            ? Number(
                (this.successes[player] / this.totalAmount[player]) * 100
              ).toFixed(1)
            : "-") + " %";
        s +=
          " (" +
          Number(this.successes[player] / divider).toFixed(1) +
          "/" +
          Number(this.totalAmount[player] / divider).toFixed(1) +
          ")";
        break;
    }
    return s;
  }

  statToString(divider) {
    let s = "";
    s += this.name + ": ";
    switch (this.key) {
      default:
        s += this.playerStatToString(0, divider);
        s += " - ";
        s += this.playerStatToString(1, divider);
        break;
    }
    return s;
  }

  playerStatToIndicatorWidth(player) {
    let width = 0;
    let total = this.successes[0] + this.successes[1];
    if (total > 0) {
      switch (this.key) {
        case "matchesWonOdds":
          width = (this.successes[player ^ 1] / total) * 100;
          break;
        default:
          width = (this.successes[player] / total) * 100;
          break;
      }
    }
    return Math.round(width) + "%";
  }

  statToRow(key, divider) {
    let firstPlayerHasMore = true;
    switch (this.key) {
      case "doubleFaults":
        firstPlayerHasMore = this.successes[0] > this.successes[1];
        break;
      default:
        firstPlayerHasMore =
          this.successes[0] / this.totalAmount[0] >
          this.successes[1] / this.totalAmount[1];
        break;
    }
    return (
      <tr key={key}>
        <td className="w-50">{this.name}</td>
        <td className="text-end">
          {this.playerStatToString(0, divider)}
          <div
            className={"indicator" + (firstPlayerHasMore ? " has-more" : "")}
            style={{ width: this.playerStatToIndicatorWidth(0) }}
          ></div>
        </td>
        <td className="text-start">
          {this.playerStatToString(1, divider)}
          <div
            className={"indicator" + (!firstPlayerHasMore ? " has-more" : "")}
            style={{ width: this.playerStatToIndicatorWidth(1) }}
          ></div>
        </td>
      </tr>
    );
  }

  statDividerRow(titleString) {
    return (
      <tr key={titleString} className="divider-row">
        <td className="w-50"></td>
        <td className="text-center" colSpan={2}>
          {titleString}
        </td>
      </tr>
    );
  }
}

class statCollectionClass {
  collection;

  statNames = {
    matchesWon: "Match win percentage",
    matchesWonOdds: "Match win odds",
    setsWon: "Set win percentage",
    gamesWon: "Game win percentage",
    pointsWon: "Total point win percentage",
    firstServeIn: "First serve percentage",
    firstServeWon: "First serve points won",
    secondServeIn: "Second serve percentage",
    secondServeWon: "Second serve points won",
    doubleFaults: "Double faults",
    breakPointsWon: "Break points converted",
    servicePointsWon: "Service points won",
    returnPointsWon: "Return points won",
  };

  constructor() {
    this.collection = {};
    for (const [key, value] of Object.entries(this.statNames)) {
      this.collection[key] = new singleStatClass(key, value);
    }
  }

  statsToList(divider) {
    let a = [];
    for (const [key, value] of Object.entries(this.collection)) {
      a.push(<li key={key}>{this.collection[key]?.statToString(divider)}</li>);
    }
    return a;
  }

  statsToTable(divider, possiblyInProgress) {
    let a = [];
    a.push(
      <tr key={divider}>
        <th>
          {possiblyInProgress
            ? "Match " + divider + " in progress"
            : "Matches played: " + divider}
        </th>
        <th className="text-end">Player 1</th>
        <th className="text-start">Player 2</th>
      </tr>
    );
    for (const [key, value] of Object.entries(this.collection)) {
      a.push(this.collection[key]?.statToRow(key, divider));
      switch (key) {
        case "pointsWon":
          a.push(this.collection[key]?.statDividerRow("Serves"));
          break;
        case "doubleFaults":
          a.push(this.collection[key]?.statDividerRow("Points"));
          break;
      }
    }
    return a;
  }

  statsToString(divider) {
    let s = "Stats: \n";
    for (const [key, value] of Object.entries(this.collection)) {
      s += this.collection[key]?.statToString(divider) + "\n";
    }
    return s;
  }
}

export default statCollectionClass;
